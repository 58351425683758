<template>
  <div style="min-height: 750px;" v-if="d_usrSupportWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['support_requests'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row style="font-size: 14px;">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin-bottom: 10px;">
          <b-col sm="12" lg="6"> </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button size="sm" @click="f_supportModal()" variant="white">
              <i class="fa fa-plus-circle"></i>
              {{ StoreLangTranslation.data['new_support_request'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" lg="12">
            <b-form-input id="search" v-model="d_searchText" :placeholder="StoreLangTranslation.data['write_for_filtering'][StoreLang]"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="1">
            <strong> {{ d_usrSupportWdm['0']['parameters']['0']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrSupportWdm['0']['parameters']['12']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="6">
            <strong> {{ d_usrSupportWdm['0']['parameters']['8']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="2">
            <strong> {{ d_usrSupportWdm['0']['parameters']['3']['name']['translation'][StoreLang] }} </strong>
          </b-col>
          <b-col sm="12" lg="1"> </b-col>
        </b-row>
        <b-row v-for="(wdmr_item, wdmr_item_ind) in d_userSuppportList" v-if="f_searchWdmritem(wdmr_item)" @mouseover="d_hoverIndex = wdmr_item_ind" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; margin: 10px; border-bottom: solid 1px #dcdcdc;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc;'">
          <b-col sm="12" lg="1">
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['0'] && wdmr_item.data['0']['0']['val']['translation']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : wdmr_item.data['0']['0']['val']['value'] === '1' ? 'color: green;' : 'color: red;'">{{ wdmr_item.data['0']['0']['val']['translation'][StoreLang] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="2">
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['12'] && wdmr_item.data['0']['12']['val']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ f_dateFormat(wdmr_item.data['0']['12']['val'], 'year-month-day hh:mm') }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['8'] && wdmr_item.data['0']['8']['lang'] && wdmr_item.data['0']['8']['lang'][StoreLang]">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['8']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['8'] && wdmr_item.data['0']['8']['val']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['8']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="2">
            <template v-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['3'] && wdmr_item.data['0']['3']['lang'] && wdmr_item.data['0']['3']['lang'][StoreLang]">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['3']['lang'][StoreLang] }}</span>
            </template>
            <template v-else-if="wdmr_item.data && wdmr_item.data['0'] && wdmr_item.data['0']['3'] && wdmr_item.data['0']['3']['val']">
              <span :style="d_hoverIndex === wdmr_item_ind ? ' color: rgb(252, 0, 153, 1);' : ''">{{ wdmr_item.data['0']['3']['val'] }}</span>
            </template>
          </b-col>
          <b-col sm="12" lg="1">
            <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
              <template slot="button-content">
                işlem
              </template>
              <b-dropdown-item v-if="d_user" @click="f_supportDetails(wdmr_item.client_id)"><i class="fa fa-edit"></i> {{ StoreLangTranslation.data['support_details'][StoreLang] }} </b-dropdown-item>
              <b-dropdown-item v-if="d_user" @click="f_userSupportTerminate(wdmr_item.client_id)"><i class="fa fa-edit"></i> {{ StoreLangTranslation.data['support_terminate'][StoreLang] }} </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_supportData.show" id="d_supportData_show" v-model="d_supportData.show" scrollable hide-footer :title="StoreLangTranslation.data['support_screen'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <support-screen :p_supportData="d_supportData" :f_userSupportList="f_userSupportList"></support-screen>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import {
  default as SupportScreen
} from '@/wam/components/widgets/SupportScreen';
import { mapGetters } from 'vuex';
import moment from "moment";
export default {
  name: 'user_support_list',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
    SupportScreen,
  },
  props: {},
  data () {
    return {
      d_supportData: {
        'mail_status': true,
        'phone_status': true,
        'phone': '',
        'mail': '',
        'show': false,
        'edit_eligible': false,
        'operation_name': '',
        'operation_note': '',
        'operation_note_additional': '',
        'maximum_characters': 500,
        'subject': 'genel'
      },
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_userSuppportList: [],
      d_usrSupportWdm: '',
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_support').then(resp_wdm_data => { this.d_usrSupportWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.d_user && this.d_user.username) {
      this.f_userSupportList();
    }
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrSupportWdm);
  },
  methods: {
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY HH:MM");
          return x;
        }
      } else {
        return "";
      }
    },
    f_supportModal: function () {
      if (this.d_user) {
        this.d_supportData.show = true;
        this.d_supportData.edit_eligible = true;
        this.d_supportData.operation_name = '';
        this.d_supportData.operation_note = '';
      } else {
        this.$router.push({ path: '/login', query: {} });
      }
    },
    f_supportDetails: function (user_support_client_id) {
      let route_path = ClsRoute.f_getRoutePath('user_support_details', this.StoreLang);
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_support_client_id': user_support_client_id } });
    },
    f_searchWdmritem: function (wdmr_item) {
      let res = false;
      if (this.d_searchText) {
        try {
          if (wdmr_item.data['0']['8']['val'].toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
            res = true;
          }
        } catch(err) {}
      } else {
        res = true;
      }
      return res;
    },
    f_userSupportList: function () {
      this.d_userSuppportList = [];
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      ServiceFunctions.user_support_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_userSuppportList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_userSupportTerminate: function (user_support_client_id) {
      this.d_userSuppportList = [];
      let query = 'user_client_id=' + this.d_user.username;
      query += '&user_support_client_id=' + user_support_client_id;
      let data = {};
      ServiceFunctions.user_support_terminate(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.f_userSupportList();
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  },
  watch: {}
};

</script>

